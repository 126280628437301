.side {
    display: none;
}

.cont {
    display: none;
}

@media (max-width: 575px) {
    .cont {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.432);
        position: fixed;
        top: 0;
        left: -100vw;
        display: block;
        z-index: 14;
        transition: .7s;
    }

    .cont.active {
        left: 0;
    }

    .close {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-top: 10px;
        margin-right: 10px;
        --tw-bg-opacity: 1;
        background-color: rgb(30 64 175 / var(--tw-bg-opacity));
        border-radius: 8px;
    }

    .side {
        z-index: 15;
        --tw-bg-opacity: 1;
        background-color: rgb(71 85 105 / var(--tw-bg-opacity));
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: -100vw;
        width: 70vw;
        height: 100%;
        transition: .7s;
    }

    .side.active {
        left: 0;
    }

    .sidebar {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        width: 100%;
        padding-left: 10px;
    }

    .sidebar li {
        line-height: 40px;
        width: 100%;
    }

    .sidebar li a {
        height: 100%;
    }

    .sidebar li a.active {
        --tw-bg-opacity: 1;
        color: rgb(30 64 175 / var(--tw-bg-opacity));
        font-size: 22px;
        font-weight: 800;
    }

    .btnSide {
        margin-top: auto;
        width: 100%;
        padding: 1rem 30px;
        display: flex;
        justify-content: center;
    }

    .btnSide button {
        width: 100%;
        height: 40px;
        --tw-bg-opacity: 1;
        background-color: rgb(30 64 175 / var(--tw-bg-opacity));
    }
}