@tailwind base;
@tailwind components;
@tailwind utilities;

.animation {
  animation: linearscroll 2s linear infinite;
}

.width70 {
  width: 70vw;
}

@keyframes linearscroll {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.grid-temp {
  grid-template-columns: 1fr 7fr 4fr;
}

.grid2 {
  grid-template-columns: 3.5fr 7.5fr;
}

.grid2nd {
  grid-template-columns: 5fr 7fr;
}

.gridInv {
  grid-template-columns: 7fr 5fr;
}

@media (max-width: 525px) {
  .grid2, .grid2nd, .gridInv {
    grid-template-columns: 12fr;
  }
}

.abt-des1 {
  position: absolute;
  left: -15vw;
  top: -13vh;
  height: 250px;
  width: 400px;
  transform: rotate(-45deg);
  z-index: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.abt-des2 {
  position: absolute;
  right: -15vw;
  bottom: -13vh;
  height: 250px;
  width: 400px;
  transform: rotate(-45deg);
  z-index: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: rgb(241 245 249);
  --secondary: rgb(30 41 59);
  --accent:  rgb(30 64 175);
}