.seeMore {
    display: none;
}

.seeMore.active {
    width: 100%;
    text-align: end;
    margin-top: 1rem;
    display: block;
}

.seeMore a {
    text-decoration: underline;
    color: var(--primary);
    font-size: 20px;
}