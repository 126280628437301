@media (max-width: 575px) {
    .landHead {
        height: 200px;
        width: 250px;
    }
}

@media (max-width: 428px) {
    .landHead {
        height: 180px;
        width: 230px;
    }
}

@media (max-width: 375px) {
    .landHead {
        height: 150px;
        width: 180px;
    }
}